
body{
    margin-top: 0;
}
.wrapper {
    width: max-content;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.label {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}
.column {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

}
.close-icon {
    margin-left: auto;
}
.auth-title {
   color: #1b1b25;
}
.description {
    width: 250px;
    margin: 20px auto;
    text-align: center;
}
.wrapper-button {
    gap: 20px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.input {
    min-width: 110px;
    height: 30px;
    border: 1px solid #ced4da;;
    padding: 0 10px;
    margin-top: auto;
}
.input-autocomplete {
    position: absolute;
    right: 0;
    top: 122px;
    width: 54%;
    background: white;
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 5;
    max-height: 240px;
    height: auto;
    overflow: auto;
}

.autocomplete-item {
    padding: 5px;
    text-align: left;
}
.autocomplete-item:hover {
    background-color: #238be6;
    color: white;
}

.input-box {
    display: flex;
    flex-direction: column;
}
.datepicker {
    width: 50px;
    border: 1px solid #ced4da;
    border-radius: 6%;
    height: 35px;
}
.button {
    margin: 0 auto;
    display: block;
    min-width: 120px;
    height: 50px;
    padding: 8px;
    border: 1px solid;
    background-color: #1b1b25;
    color: white;
    margin-bottom: 5px;
}
.auth {
    min-width: 250px;
    height: 55px;
}

.add {
    margin-bottom: 20px;
}

.delete-button {
    background-color: transparent;
    border:none;
}
.button-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.button-container > button:first-child {
    flex: 14;
}

.button-container > button:nth-child(2),
.button-container > button:nth-child(3) {
    flex: 1;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90px;
}

.link {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.link-wrapper {
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #0d8ae4;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d8ae4;
    margin: 0 auto;
}

.header {
    background-color: #f5f5f5;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    margin: 0;
}


.header__wrapper {
    display: flex;
    padding-right: 7.5%;
    padding-left: 7.5%;
}

.header__left {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    margin-right: auto;
}

.filter-container {
    margin : 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1000px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: end;
    justify-content: center;
}

.checkbox-wrapper {

    padding: 7px 7px 7px 7px;

    background-color: #d0e1fc;
}


.m-5f75b09e[data-label-position=right] {
    --_label-order: 2;
    --_offset-right: 0;
    --_offset-left: 5px;
}

.container {
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    border: 1px solid #ced4da;
    min-height: 100px;
    width: 85%;
    position: relative;
}

/*.container > * {*/
/*    overflow: auto;*/
/*}*/



.remove-button {
    border:none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.remove-button > * {
    margin-top: 17px;
}

.input-container {
    display: flex;
    gap:5px;
}
.link {
    text-decoration: none;
    color: #1b1b25;
    font-size: 18px;
}
.add-input {
    width: 100px;
    padding: 5px;
    margin: 5px auto;
}

.header__account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.table-container {
    font-size: 16px;
    line-height: 1.4;
    font-family: "Times New Roman";
}


.myTable {
    width: 85%;
    margin: auto;
}

.myTable, td, th {
    border-collapse: collapse;
}

@media screen and (max-width: 1000px) {
    .container {
        width: 95%;
    }
    .myTable {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }
}

@media screen and (max-width: 520px) {
    .input-container {
        flex-direction: column;
    }
    .remove-button svg{
        margin: 0 auto;
    }
}

@media screen and (max-width: 520px) {
    .input-container {
        flex-direction: column;
    }
    .remove-button svg{
        margin: 0 auto;
    }
}